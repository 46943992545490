
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@common/src/utils/common";
import dayjs from "dayjs";
import { Table, TableRowsSelect } from "@common/src/utils/decorator";
import BtnRadio from "@/components/scope/btn-radio.vue";
import FilterBar from "@common/src/components/scope/filter-bar.vue";
import { formDownload } from "@common/src/utils/tools";
import t from "@common/src/i18n/t";
import appCode from "@common/src/utils/app-code";
const statement = namespace("statement");
const base = namespace("base");
const management = namespace("management");
@Component({
  components: { BtnRadio, FilterBar },
  filters: {}
})
@Table("loadListData")
export default class Statement extends Vue {
  @statement.Action getCustomerSituationPageModelPage;
  @base.Action getDictionaryList;
  @management.Action getStoreList;

  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  defaultParams: any = {
    storeCode: "S00210319LS",
    storeName: t("v210831.jiajiele-industry-(shanghai)")
  };
  searchForm: any = {
    storeCode: this.defaultParams.storeCode, // 门店名称
    area: "", // 所在地区
    stateName: "", // 省级名称
    cityName: "", // 市级名称
    districtName: "", // 县级名称
    timeFrameType: "", // 时间范围类型, date-按日,month-按月
    startTime: "", // 开始时间 格式: 按日为yyyyMMdd, 按月为yyyyMM
    endTime: "", // 结束时间 格式: 按日为yyyyMMdd, 按月为yyyyMM
    businessType: "", // 服务类型，services-服务项目,parts-零配件
    businessCodeList: [] // 服务编码列表
  };
  contentList: any = [];
  dataLastUpdateTime: any = "";
  cumulativeCustomersNum: any = "";

  created() {
    if (appCode === "speedbiz") {
      this.searchForm.storeCode = "";
    }
    this.init();
  }
  toStatementView(row) {
    let searchForm = this.searchForm;
    let searchList = this.selfData.length ? this.selfData : this.searchList;
    if (row) {
      let dateTip = row.dateTip.split("-").join("");
      searchForm.startTime = dateTip;
      searchForm.endTime = dateTip;
      searchList.forEach(item => {
        if (item.prop === "timeFrameType") {
          item.selectList.forEach(part => {
            part.itemValue = [dateTip, dateTip];
          });
        }
      });
    }
    localStorage.setItem("searchFormData", JSON.stringify(searchForm));
    localStorage.setItem("searchListData", JSON.stringify(searchList));
    if (!this.selfData.length && !row) {
      localStorage.removeItem("searchFormData");
      localStorage.removeItem("searchListData");
    }
    this.$router.push("/statement/customer-view");
  }
  getCurrentTime() {
    // 获取当前时间
    let timestamp = new Date().getTime() - 24 * 60 * 60 * 1000;
    let currentTime = new Date(timestamp);
    let year = String(currentTime.getFullYear());
    let month = this.addZero(currentTime.getMonth() + 1);
    let day = this.addZero(currentTime.getDate());
    this.searchForm.timeFrameType = "date";
    this.searchForm.startTime = year + month + "01";
    this.searchForm.endTime = year + month + day;
  }
  addZero(num) {
    return num < 10 ? "0" + num : String(num);
  }
  mounted() {}
  storeList: any = [];
  selfData: any = [];
  get searchList() {
    let storeRow = [
      {
        label: t("v210831.store-name"),
        type: "select-search",
        value: this.defaultParams.storeName,
        prop: "storeCode",
        selectList: this.storeList,
        searchFun: this.searchStore
      },
      {
        label: t("setting.region"),
        type: "area",
        value: "",
        labels: [],
        prop: "area",
        anyLevel: true,
        labelWidth: "85px"
      }
    ];
    let timeRow = [
      {
        label: t("v210831.time-frame"),
        type: "select-view",
        value: "",
        prop: "timeFrameType",
        // 时间范围区间必须是同一年
        isSameYear: true,
        selectList: [
          {
            value: "date",
            label: t("v210831.query-by-day"),
            itemValue: "",
            itemType: "daterange",
            itemProp: "dateValue",
            itemValueFormat: "yyyyMMdd"
          },
          {
            value: "month",
            label: t("v210831.query-by-month"),
            itemValue: "",
            itemType: "monthrange",
            itemProp: "dateValue",
            itemValueFormat: "yyyyMM"
          }
        ]
      }
    ];
    if (appCode === "speedbiz-operation") {
      return [...storeRow, ...timeRow];
    } else if (appCode === "speedbiz") {
      return timeRow;
    }
    return [];
  }
  searchStore(val = "") {
    this.getStoreList({
      pageNum: 1,
      pageSize: 30,
      storeName: val
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
        : [];
    });
  }
  init() {
    if (appCode === "speedbiz-operation") {
      this.searchStore("");
    }
    this.getCurrentTime();
    this.loadListData();
  }
  handleExportData() {
    formDownload(`${process.env.VUE_APP_URL_API}/report/customerSituation/exportCustomerSituationPage`, this.searchForm);
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getCustomerSituationPageModelPage({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      if (data.data.dataLastUpdateTime) {
        this.dataLastUpdateTime = data.data.dataLastUpdateTime;
      }
      this.cumulativeCustomersNum = data.data.cumulativeCustomersNum;
      this.contentList = data.data.list;
      return data;
    });
  }
  @TableRowsSelect.updateMethod
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "area") {
          this.searchForm.stateName = item.labels[0] || "";
          this.searchForm.cityName = item.labels[1] || "";
          this.searchForm.districtName = item.labels[2] || "";
        } else if (item.prop === "storeCode" && item.value === this.defaultParams.storeName) {
          this.searchForm[item.prop] = this.defaultParams.storeCode;
        } else {
          this.searchForm[item.prop] = item.value;
        }
        if (item.type === "select-view") {
          item.selectList.forEach(val => {
            if (val.value === item.value) {
              if (val.itemProp === "dateValue") {
                this.searchForm.startTime = val.itemValue[0] || "";
                this.searchForm.endTime = val.itemValue[1] || "";
              } else {
                this.searchForm[val.itemProp] = val.itemValue;
              }
            }
          });
        }
      }
    });
    this.selfData = data;
    this.loadListData();
  }
}
